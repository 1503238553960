import queryString from 'query-string'

import { get, patch, post, del } from '../../utils'
import { API_LIST_ZONE, API_DESTINATION, API_DESTINATION_SEARCH, API_DESTINATION_LIST_MEMBER } from '../api-constraint'

export const LOAD_LIST_ZONE_REQUEST = 'LOAD_LIST_ZONE_REQUEST'
export const LOAD_LIST_ZONE_SUCCESS = 'LOAD_LIST_ZONE_SUCCESS'
export const LOAD_LIST_ZONE_FAILURE = 'LOAD_LIST_ZONE_FAILURE'
export const LOAD_LIST_ZONE_IDLE = 'LOAD_LIST_ZONE_IDLE'

export const loadListZoneRequest = () => {
  return {
    type: LOAD_LIST_ZONE_REQUEST,
  }
}

export const loadListZoneSuccess = listZone => {
  return {
    type: LOAD_LIST_ZONE_SUCCESS,
    payload: {
      listZone,
    },
  }
}

export const loadListZoneFailure = error => {
  return {
    type: LOAD_LIST_ZONE_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListZoneIdle = () => {
  return {
    type: LOAD_LIST_ZONE_IDLE,
  }
}

export const loadListZone = (options = {}) => {
  return async dispatch => {
    const { cookie } = options
    dispatch(loadListZoneRequest())
    try {
      const listZone = await get({
        path: `${API_LIST_ZONE}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadListZoneSuccess(listZone))
    } catch (err) {
      dispatch(loadListZoneFailure(err))
    } finally {
      dispatch(loadListZoneIdle())
    }
  }
}

export const LOAD_TENANT_REQUEST = 'LOAD_TENANT_REQUEST'
export const LOAD_TENANT_SUCCESS = 'LOAD_TENANT_SUCCESS'
export const LOAD_TENANT_FAILURE = 'LOAD_TENANT_FAILURE'
export const LOAD_TENANT_IDLE = 'LOAD_TENANT_IDLE'

export const loadTenantRequest = () => {
  return {
    type: LOAD_TENANT_REQUEST,
  }
}

export const loadTenantSuccess = tenantData => {
  return {
    type: LOAD_TENANT_SUCCESS,
    payload: {
      tenantData,
    },
  }
}

export const loadTenantFailure = error => {
  return {
    type: LOAD_TENANT_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadTenantIdle = () => {
  return {
    type: LOAD_TENANT_IDLE,
  }
}

export const loadTenant = (options = {}) => {
  return async dispatch => {
    const { cookie, id } = options
    dispatch(loadTenantRequest())
    try {
      const tenantData = await get({
        path: `${API_DESTINATION}/${id}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadTenantSuccess(tenantData))
    } catch (err) {
      dispatch(loadTenantFailure(err))
    } finally {
      dispatch(loadTenantIdle())
    }
  }
}

export const CREATE_TENANT_REQUEST = 'CREATE_TENANT_REQUEST'
export const CREATE_TENANT_SUCCESS = 'CREATE_TENANT_SUCCESS'
export const CREATE_TENANT_FAILURE = 'CREATE_TENANT_FAILURE'
export const CREATE_TENANT_IDLE = 'CREATE_TENANT_IDLE'

export const createTenantRequest = () => {
  return {
    type: CREATE_TENANT_REQUEST,
  }
}

export const createTenantSuccess = tenantData => {
  return {
    type: CREATE_TENANT_SUCCESS,
    payload: {
      tenantData,
    },
  }
}

export const createTenantFailure = error => {
  return {
    type: CREATE_TENANT_FAILURE,
    payload: {
      error,
    },
  }
}

export const createTenantIdle = () => {
  return {
    type: CREATE_TENANT_IDLE,
  }
}

export const createTenant = data => {
  return async dispatch => {
    dispatch(createTenantRequest())
    try {
      const tenantData = await post({
        path: `${API_DESTINATION}`,
        body: data,
      })
      dispatch(createTenantSuccess(tenantData))
    } catch (err) {
      dispatch(createTenantFailure(err))
    } finally {
      dispatch(createTenantIdle())
    }
  }
}

export const UPDATE_TENANT_REQUEST = 'UPDATE_TENANT_REQUEST'
export const UPDATE_TENANT_SUCCESS = 'UPDATE_TENANT_SUCCESS'
export const UPDATE_TENANT_FAILURE = 'UPDATE_TENANT_FAILURE'
export const UPDATE_TENANT_IDLE = 'UPDATE_TENANT_IDLE'

export const updateTenantRequest = () => {
  return {
    type: UPDATE_TENANT_REQUEST,
  }
}

export const updateTenantSuccess = tenantData => {
  return {
    type: UPDATE_TENANT_SUCCESS,
    payload: {
      tenantData,
    },
  }
}

export const updateTenantFailure = error => {
  return {
    type: UPDATE_TENANT_FAILURE,
    payload: {
      error,
    },
  }
}

export const updateTenantIdle = () => {
  return {
    type: UPDATE_TENANT_IDLE,
  }
}

export const updateTenant = data => {
  return async dispatch => {
    dispatch(updateTenantRequest())
    try {
      const updatedTenantData = await patch({
        path: `${API_DESTINATION}/${data.id}`,
        body: data,
      })
      dispatch(updateTenantSuccess(updatedTenantData))
    } catch (err) {
      dispatch(updateTenantFailure(err))
    } finally {
      dispatch(updateTenantIdle())
    }
  }
}

export const DELETE_TENANT_REQUEST = 'DELETE_TENANT_REQUEST'
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS'
export const DELETE_TENANT_FAILURE = 'DELETE_TENANT_FAILURE'
export const DELETE_TENANT_IDLE = 'DELETE_TENANT_IDLE'

export const deleteTenantRequest = () => {
  return {
    type: DELETE_TENANT_REQUEST,
  }
}

export const deleteTenantSuccess = () => {
  return {
    type: DELETE_TENANT_SUCCESS,
  }
}

export const deleteTenantFailure = error => {
  return {
    type: DELETE_TENANT_FAILURE,
    payload: {
      error,
    },
  }
}

export const deleteTenantIdle = () => {
  return {
    type: DELETE_TENANT_IDLE,
  }
}

export const deleteTenant = id => {
  return async dispatch => {
    dispatch(deleteTenantRequest())
    try {
      await del({
        path: `${API_DESTINATION}/${id}`,
      })
      dispatch(deleteTenantSuccess())
    } catch (err) {
      dispatch(deleteTenantFailure(err))
    } finally {
      dispatch(deleteTenantIdle())
    }
  }
}

export const LOAD_LIST_TENANT_REQUEST = 'LOAD_LIST_TENANT_REQUEST'
export const LOAD_LIST_TENANT_SUCCESS = 'LOAD_LIST_TENANT_SUCCESS'
export const LOAD_LIST_TENANT_FAILURE = 'LOAD_LIST_TENANT_FAILURE'
export const LOAD_LIST_TENANT_IDLE = 'LOAD_LIST_TENANT_IDLE'

export const loadListTenantRequest = () => {
  return {
    type: LOAD_LIST_TENANT_REQUEST,
  }
}

export const loadListTenantSuccess = ({ totalItems, totalPages, page, data }) => {
  return {
    type: LOAD_LIST_TENANT_SUCCESS,
    payload: {
      totalItems,
      totalPages,
      page,
      data,
    },
  }
}

export const loadListTenantFailure = error => {
  return {
    type: LOAD_LIST_TENANT_FAILURE,
    payload: { error },
  }
}

export const loadListTenantIdle = () => {
  return {
    type: LOAD_LIST_TENANT_IDLE,
  }
}

export const loadListTenant = (options = {}) => {
  return async dispatch => {
    const { cookie, limit } = options
    const query = queryString.stringify({
      limit,
    })
    dispatch(loadListTenantRequest())
    try {
      const response = await get({
        path: `${API_DESTINATION_SEARCH}?${query}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadListTenantSuccess(response))
    } catch (err) {
      dispatch(loadListTenantFailure(err))
    } finally {
      dispatch(loadListTenantIdle())
    }
  }
}

export const LOAD_LIST_MEMBER_IN_TENANT_REQUEST = 'LOAD_LIST_MEMBER_IN_TENANT_REQUEST'
export const LOAD_LIST_MEMBER_IN_TENANT_SUCCESS = 'LOAD_LIST_MEMBER_IN_TENANT_SUCCESS'
export const LOAD_LIST_MEMBER_IN_TENANT_FAILURE = 'LOAD_LIST_MEMBER_IN_TENANT_FAILURE'
export const LOAD_LIST_MEMBER_IN_TENANT_IDLE = 'LOAD_LIST_MEMBER_IN_TENANT_IDLE'

export const loadListMemberInTenantRequest = id => {
  return {
    type: LOAD_LIST_MEMBER_IN_TENANT_REQUEST,
    payload: {
      id,
    },
  }
}

export const loadListMemberInTenantSuccess = listMember => {
  return {
    type: LOAD_LIST_MEMBER_IN_TENANT_SUCCESS,
    payload: {
      listMember,
    },
  }
}

export const loadListMemberInTenantFailure = error => {
  return {
    type: LOAD_LIST_MEMBER_IN_TENANT_FAILURE,
    payload: { error },
  }
}

export const loadListMemberInTenantIdle = () => {
  return {
    type: LOAD_LIST_MEMBER_IN_TENANT_IDLE,
  }
}

export const loadListMemberInTenant = id => {
  return async dispatch => {
    dispatch(loadListMemberInTenantRequest(id))
    const path = API_DESTINATION_LIST_MEMBER.replace(':id', id)
    try {
      const listMember = await get({ path })
      dispatch(loadListMemberInTenantSuccess(listMember))
    } catch (err) {
      dispatch(loadListMemberInTenantFailure(err))
    } finally {
      dispatch(loadListMemberInTenantIdle())
    }
  }
}
